import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

const CollectionTitleWithCTA = ({ title, className = "" }) => {
  if (!title) {
    return null;
  }

  return (
    <h2 className={`${styles.title} ${className}`}>
      <span className={styles.text}>{title}</span>
      <div className={styles["collection-cta"]}>
        <Icon type={"slider-next"} />
      </div>
    </h2>
  );
};

CollectionTitleWithCTA.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CollectionTitleWithCTA;
