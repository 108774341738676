import React from "react";
import PropTypes from "prop-types";

let taboolaStarted = false;
let taboolaTimeout = null;
function startTaboola() {
  if (taboolaStarted) {
    return;
  }

  taboolaStarted = true;

  // eslint-disable-next-line no-unused-expressions
  !(function(e, f, u, i) {
    if (!document.getElementById(i)) {
      e.src = u;
      e.id = i;
      e.defer = true;
      f.parentNode.insertBefore(e, f);
    }
  })(
    document.createElement("script"),
    document.getElementsByTagName("script")[0],
    "//cdn.taboola.com/libtrc/vikatandigital-vikatancom/loader.js",
    "tb_loader_script"
  );
  if (window.performance && typeof window.performance.mark === "function") {
    window.performance.mark("tbl_ic");
  }
}

class TaboolaAdBase extends React.Component {
  static counter = 1;
  constructor() {
    super();
    this.counter = TaboolaAd.counter++;
    this.state = {
      userType: "F"
    };
  }
  componentDidMount() {
    global.userPromise.catch(() => null).then(user => {
      user && this.setState({ userType: user.userType });
    });
    taboolaTimeout = setTimeout(() => {
      startTaboola();
      global._taboola = global._taboola || [];
      global._taboola.push({
        mode: `${this.props.config.mode}`,
        container: `${this.props.config.id}-${this.counter}`,
        placement: `${this.props.config.placement}`,
        target_type: "mix"
      });
      // The GA custom event:
      global.gtag &&
        global.gtag("event", "taboola", {
          event_category: "PV",
          event_label: this.props.config.placement
        });
      if (this.props.directory === "article") {
        global._taboola.push({ article: "auto" });
      }
      if (this.props.directory === "home") {
        global._taboola.push({ home: "auto" });
      }
      if (this.props.directory === "category") {
        global._taboola.push({ category: "auto" });
      }
      global._taboola.push({ flush: true });
    }, 9000);
  }

  componentWillUnmount() {
    // Clear the timeout when the component is unmounted
    clearTimeout(taboolaTimeout);
  }

  render() {
    const { userType } = this.state;

    return (
      <div className={` ${this.props.className}`}>
        {userType === "P" || userType === "T" || userType === "R" ? null : (
          <div id={`${this.props.config.id}-${this.counter}`} />
        )}
      </div>
    );
  }
}

TaboolaAdBase.propTypes = {
  config: PropTypes.shape({
    mode: PropTypes.string,
    id: PropTypes.string,
    placement: PropTypes.string
  }),
  url: PropTypes.string,
  className: PropTypes.string,
  directory: PropTypes.string
};

export function TaboolaAd(props) {
  return <TaboolaAdBase {...props} />;
}
