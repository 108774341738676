import { wrapCollectionLayout } from "@quintype/components";
import FourStoryHalfFeatured from "../rows/four-story-half-featured";
import FourStory4s from "../rows/four-story-4s";
import { SevenStory7s, VikatanTV } from "../rows/seven-story-7s";
import { SevenStory7sfirstbigcard } from "../rows/seven-story-7s-1bigcard";
import { SevenStory7sWithNeet } from "../rows/seven-story-7s-with-neet";
import SixteenStory4C from "../rows/sixteen-story-4c";
import Slider1S from "../rows/slider-1s";
import SliderFocusedCard from "../rows/slider-focused-card";
import FirewordUnit from "../rows/fireword-unit";
import SliderMainCard from "../rows/slider-main-card";
import MagazineSubscription from "../rows/magazine-subscription";
import MainRow from "../rows/main-row";
import ThreeStorySliderRound from "../rows/three-story-slider-round";
import StorySliderAppExclusive from "../rows/story-slider-round-16";
import FiveStoryOneAdOneWidget from "../rows/five-story-one-ad-one-widget";
import FourStoryPhotoGallery from "../rows/four-story-photo-gallery";
import InvertedFourStoryHalfFeatured from "../rows/inverted-four-story-half-featured";
import CustomFourStoryHalfFeatured from "../rows/custom-four-story-half-featured";
import SubdomainsStoryCard from "../rows/subdomains-story-collection";
import NineteenStoriesOneAd from "../rows/nineteen-stories-one-ad";
import OneCarouselTwoStoriesOneAdOneWidget from "../rows/one-carousel-two-stories-one-ad-one-widget";
import TwelveStoriesOneAdOneWidget from "../rows/twelve-stories-one-ad-one-widget";
import FiveStoryOneAd from "../rows/five-stories-one-ad";
import TwoCollectionFourStories from "../rows/two-collection-four-stories";
import ThreeStoryAutoSlider from "../rows/three-story-autoslider";
import TwelveStoriesOneAd from "../rows/twelve-stories-one-ad";
import MainRowSpecialBundle from "../rows/main-row-special-bundle";
import kidsStories from "../rows/kids-stories";
import SomaValli from "../rows/soma-valiappan";
import ElephantStories from "../rows/elephant-stories";
import Saibaba from "../rows/saibaba";
import CoronaWidgetOneStoryPdf from "../rows/corona-widget-one-story-pdf";
import FaqWithForm from "../rows/faq-with-form";
import SixStory2C from "../rows/six-story-2c";
import SixStoryTwoBigStory from "../rows/six-story-two-big-story";
import DoubleStorySlider from "../rows/double-story-slider";
import CollectionStorySlider from "../rows/collection-with-story-slider";
import EightStory2c from "../rows/eight-story-2c";
import TwocollectionsStorySlider from "../rows/two-story-2c";
import CollectionsSlider from "../rows/collection-slider";
import GoldStory from "../rows/gold-story";
import SilverStory from "../rows/silver-story";
import IplRecentmatch from "../rows/ipl-recent-match";
import IplPointTable from "../rows/ipl-point-table";
import IplPointTable2021 from "../rows/ipl-point-table-2021";
import SliderWenStoriesCard from "../rows/slider-web-stories-card";
import SanipeyarchiCollection from "../rows/sanipeyarchi-collection";
import SliderVideoCard from "../rows/slider-video-card";
import FiveStoryAutoSliderWithVideo from "../rows/five-story-autoslider-with-video";
import FiveStoryAutoSliderWithStory from "../rows/five-story-autoslider-with-story";
import FourteenStories from "../rows/fourteen-stories";
import EightStory4CWithBanner from "../rows/eight-story-2c-with-banner";
import WebstoriesFbUint from "../rows/webstories-with-fb-unit";
import StorySliderWithIframe from "../rows/story-slider-with-custom-frame";
import OneStoriesSliderOneStorySlider from "../rows/one-stories-slider-one-story-slider";
import StarCandidateResult from "../rows/star-candidate-result";
import StateElectionResult from "../rows/states-election-results";
import TnElectionResult from "../rows/tn-election-results";
import SliderFocusedCardBundle from "../rows/slider-focused-card-bundle";
import ViselectCardBundleList from "../rows/viselect-card-list-bundle";
import SliderFocusedCardRectangle from "../rows/slider-focused-card-rectangle";
import ThreeCollectionSliderRound from "../rows/three-collection-slider-round";
import PodcastLandingPage from "../rows/podcast-four-collection";
import SingleStoryBanner from "../rows/single-story-banner";
import SliderSeriesCard from "../rows/slider-focused-card-series";
import SeriesCollectionCardList from "../rows/series-collection-card-list";
import SliderPremiumCollectionCard from "../rows/slider-premium-collection-card";
import TwelveStory3C from "../rows/twelve-story-3c";
import FourteenStory2C from "../rows/fourteen-story-2c";
import SixStoriesWithCard from "../rows/six-stories-with-card";
import SliderFocusedCardWithTextOverley from "../rows/slider-focused-card-with-text-overley";
import MagazineCollection from "../rows/magazine-collection";
import EightStory8sTemplate from "../rows/eight-story-8s-template";
import SevenStory1C from "../rows/seven-story-1c";
import SixStory6c from "../rows/six-stories-collection";
import FourStoryOneAdOneWidget from "../rows/four-story-one-ad-one-widget";

import SixStoryFourcollectionOnevideoOneImage from "../rows/six-story-4c-1v-1series";
import FourCollectionWithVikatanDeals from "../rows/six-story-4c-game-section";
import FourStorySponcerContentOneAdOneWidget from "../rows/four-story-sponcer-content-one-ad-one-widget";
import MagazineSixStoryVikatanPoll from "../rows/six-magazine-stories-vikatan-poll";
import WCMatchList from "../rows/wc-match-list";
import HomeWCCollection from "../rows/wc-collection-stories";

import LoksabhaElectionPoll from "../rows/loksabha-election-poll";
import LoksabhaElectionSearchBar from "../rows/loksabha-election-dropdown";
import LoksabhaElectionCommentsWidget from "../rows/loksabha-election-comment-widget";
import IplCollection from "../rows/ipl-collection-stories";

import LoksabhaElectionHomeCollection from "../rows/election-home-collection";

export default {
  "main-row-with-bundle-12s-5c-1ad": MainRow,
  "main-row-with-special-bundle-12s-1c-1ad": MainRowSpecialBundle,
  "slider-focused-card-ns": wrapCollectionLayout(SliderFocusedCard),
  "slider-main-card-ns": wrapCollectionLayout(SliderMainCard),
  "four-story-half-featured-4s": wrapCollectionLayout(FourStoryHalfFeatured),
  "amoeba-slider-ns": wrapCollectionLayout(Slider1S),
  "seven-media-stories-7s": wrapCollectionLayout(SevenStory7s),
  "video-collection-template": wrapCollectionLayout(SevenStory7sfirstbigcard),
  "seven-media-stories-7s-with-neet": wrapCollectionLayout(SevenStory7sWithNeet),
  "vikatan-tv": wrapCollectionLayout(VikatanTV),
  "sixteen-story-4c": SixteenStory4C,
  "gradient-cards-four-story-4s": wrapCollectionLayout(FourStory4s),
  "magazine-subscription-slider-nc": MagazineSubscription,
  "three-story-slider-round": wrapCollectionLayout(ThreeStorySliderRound),
  "app-exclusive-story-slider-round": wrapCollectionLayout(StorySliderAppExclusive),
  "five-story-one-ad-one-widget": wrapCollectionLayout(FiveStoryOneAdOneWidget),
  "four-story-photo-gallery": wrapCollectionLayout(FourStoryPhotoGallery),
  "inverted-four-story-half-featured-4s": wrapCollectionLayout(InvertedFourStoryHalfFeatured),
  "custom-four-story-half-featured-4s": wrapCollectionLayout(CustomFourStoryHalfFeatured),
  "nineteen-stories-one-ad-19s": wrapCollectionLayout(NineteenStoriesOneAd),
  "one-carousel-two-stories-one-ad-one-widget-7s": wrapCollectionLayout(OneCarouselTwoStoriesOneAdOneWidget),
  "4S-2C4S-1Ad-1Widget": wrapCollectionLayout(TwelveStoriesOneAdOneWidget),
  "twelve-stories-one-ad-one-widget-12s": wrapCollectionLayout(TwelveStoriesOneAdOneWidget),
  "five-story-one-ad": wrapCollectionLayout(FiveStoryOneAd),
  "two-collection-four-story": TwoCollectionFourStories,
  "three-story-autoslider-3s": wrapCollectionLayout(ThreeStoryAutoSlider),
  "twelve-stories-one-ad-12s": wrapCollectionLayout(TwelveStoriesOneAd),
  "kids-bed-time-stories": wrapCollectionLayout(kidsStories),
  "Soma-valliappan": wrapCollectionLayout(SomaValli),
  "Elephant-stories": wrapCollectionLayout(ElephantStories),
  "saibaba-stories": wrapCollectionLayout(Saibaba),
  "spl-corona-widget-1s": wrapCollectionLayout(CoronaWidgetOneStoryPdf),
  "faq-with-form": wrapCollectionLayout(FaqWithForm),
  "six-story-2c": wrapCollectionLayout(SixStory2C),
  "six-story-two-big-story": wrapCollectionLayout(SixStoryTwoBigStory),
  "double-story-slider": wrapCollectionLayout(DoubleStorySlider),
  "collection-with-story-slider": wrapCollectionLayout(CollectionStorySlider),
  "eight-story-2c": wrapCollectionLayout(EightStory2c),
  "two-collection-with-story-slider": wrapCollectionLayout(TwocollectionsStorySlider),
  "collections-slider": wrapCollectionLayout(CollectionsSlider),
  "gold-story-grid": wrapCollectionLayout(GoldStory),
  "silver-story-grid": wrapCollectionLayout(SilverStory),
  "ipl-match-card": IplRecentmatch,
  "ipl-point-table": IplPointTable,
  "ipl-point-table-2021": IplPointTable2021,
  "slider-web-stories-card": wrapCollectionLayout(SliderWenStoriesCard),
  "sanipeyarchi-collection": SanipeyarchiCollection,
  "slider-video-card": wrapCollectionLayout(SliderVideoCard),
  "fivestory-autoslider-with-video": wrapCollectionLayout(FiveStoryAutoSliderWithVideo),
  "fivestory-autoslider-with-story": wrapCollectionLayout(FiveStoryAutoSliderWithStory),
  "fourteen-stories": FourteenStories,
  "firework-unit": FirewordUnit,
  "star-candidate-result": StarCandidateResult,
  "states-election-results": StateElectionResult,
  "tn-election-results": TnElectionResult,
  "8-story-2-collection-with-banner": EightStory4CWithBanner,
  "webstories-FbUint": wrapCollectionLayout(WebstoriesFbUint),
  "story-slider-with-iframe": wrapCollectionLayout(StorySliderWithIframe),
  "onestories-slider-onestory-slider": wrapCollectionLayout(OneStoriesSliderOneStorySlider),
  "bundle-magazine-slider": SliderFocusedCardBundle,
  "bundle-magazine-card-list": ViselectCardBundleList,
  "bundle-magazine-rectangle-slider": SliderFocusedCardRectangle,
  "series-collection-slider": ThreeCollectionSliderRound,
  "single-story-card": wrapCollectionLayout(SingleStoryBanner),
  "series-slider-card": wrapCollectionLayout(SliderSeriesCard),
  "vikatan-podcasts-collection": PodcastLandingPage,
  "premium-series-collection": SeriesCollectionCardList,
  "premium-slider-collection-card": SliderPremiumCollectionCard,
  "twelve-story-3c": TwelveStory3C,
  "fourteen-story-2c": FourteenStory2C,
  "six-stories-6s": SixStoriesWithCard,
  "multiple-story-slider-card": SliderFocusedCardWithTextOverley,
  "magazine-collection-template": MagazineCollection,
  "eight-stories-8s": EightStory8sTemplate,
  "seven-story-one-collection-template": SevenStory1C,
  "six-story-card": SixStory6c,
  "four-story-one-ad-one-widget": wrapCollectionLayout(FourStoryOneAdOneWidget),
  "wc-match-list": WCMatchList,
  "four-collection-six-stories-one-video-one-series-image": SixStoryFourcollectionOnevideoOneImage,
  "four-collection-game-section": FourCollectionWithVikatanDeals,
  "four-story-sponcer-content-one-ad-one-widget": wrapCollectionLayout(FourStorySponcerContentOneAdOneWidget),
  "magazine-six-story-vikatan-poll": wrapCollectionLayout(MagazineSixStoryVikatanPoll),
  "worldcup-collection": HomeWCCollection,
  "subdomain-story-template": wrapCollectionLayout(SubdomainsStoryCard),
  "loksabha-poll-widget": LoksabhaElectionPoll,
  "loksabha-searchbar-widget": LoksabhaElectionSearchBar,
  "loksabha-comment-widget": LoksabhaElectionCommentsWidget,
  "ipl-collection": IplCollection,
  "election-home-collection": LoksabhaElectionHomeCollection,
  defaultTemplate: wrapCollectionLayout(FourStory4s)
};
